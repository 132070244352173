import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import { gsap, ScrollTrigger } from 'gsap/all'
import router from '../router'
import imagesLoaded from 'imagesloaded'

gsap.registerPlugin(ScrollTrigger)

let route = null
gsap.defaults({
  duration: 0.3,
  ease: 'power2'
})
export default (menu, currentClass) => {
  const options = [
    {
      from: '(.*)', // meaning any
      to: '(.*)', // meaning any
      out: (next) => {
        if (document.querySelector('.menu-button').classList.contains('open')) {
          menu.closeMenu()
          document.querySelector('.menu-button').classList.remove('open')
        }
        gsap.to(document.querySelector('.content-main'), 0.5, {
          opacity: 0,
          y: -160,
          onComplete: async () => {
            if(!route) route = await currentClass
            route.destroy()
            next()
          }
        })
      },
      in: (next) => {
        gsap.set(document.querySelector('.content-main'), { y: 160, opacity: 0 })
        imagesLoaded(document.querySelector('.content-main'), async () => {
          route = await router()
          gsap.to(document.querySelector('.content-main'), 0.5, {
            opacity: 1,
            y: 0,
            onComplete: () => {
              next()
              menu.setActiveGlobal()
              ScrollTrigger.refresh()
            },
            clearProps: 'all'
          })
        })
      }
    }
  ]

  const swup = new Swup({
    linkSelector: 'a[href^="/"]:not([data-no-swup]):not([href^="mailto"]):not([href^="tel"]):not([target="_blank"]):not([href^="#"]), a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]):not([href^="mailto"]):not([href^="tel"]):not([href^="#"])',
    plugins: [new SwupJsPlugin(options), new SwupScrollPlugin({
      doScrollingRightAway: true,
      animateScroll: false
    })],
    containers: ['.content-main'],
    animateHistoryBrowsing: true,
    cache: true
  })

  window.swup = swup
}