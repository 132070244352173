import { gsap } from 'gsap/all'

export class Menu {
  constructor(el){
    this.menu = el
    this.button = this.menu.querySelector('.menu-button')
  }

  init = () => {
    this.button.addEventListener('click', this.buttonHandler)
  }

  buttonHandler = () => {
    this.button.classList.toggle('open')
    if(this.button.classList.contains('open')){
      gsap.to(this.menu.querySelector('.main-menu'), {
        height: 'auto',
        duration: 0.3,
        ease: 'power2'
      })
    }else{
      gsap.to(this.menu.querySelector('.main-menu'), {
        height: 0,
        duration: 0.3,
        ease: 'power2'
      })
    }
  }

  closeMenu = () => {
    gsap.to(this.menu.querySelector('.main-menu'), {
      height: 0,
      duration: 0.3,
      ease: 'power2'
    })
  }

  setActiveGlobal = () => {
    const path = window.location.pathname
    const currentLink = this.menu.querySelector(`a.menu-link[href*="${path}"]`)

    this.menu.querySelectorAll(`.menu-link`).forEach(link => {
      link.classList.remove('active', 'sm:text-cerulean', 'bg-navy')
    })
    
    if(currentLink && path !== '/'){
      if(currentLink.classList.contains('sub-menu-link')){
        currentLink.parentElement.parentElement.parentElement.querySelector('.sub-link').classList.add('active', 'sm:text-cerulean', 'bg-navy')
      }else{
        currentLink.classList.add('active', 'sm:text-cerulean', 'bg-navy')
      }

    }else{
      this.menu.querySelectorAll(`a.menu-link`).forEach(link => {
        link.classList.remove('active')
      })
    }
  }
}